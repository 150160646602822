.sendbird-channel-preview__leave--mobile .sendbird-modal__content {
  max-width: calc(100% - 80px);
}
.sendbird-channel-preview__leave--mobile .sendbird-modal__close {
  display: none;
}
.sendbird-channel-preview__leave--mobile .sendbird-channel-preview__leave-label--mobile {
  text-decoration: none;
}
.sendbird-channel-preview__leave--mobile .sendbird-modal__header span {
  display: inline-block;
  width: calc(100% - 120px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sendbird__offline .sendbird-channel-preview {
  cursor: not-allowed;
}

.sendbird-channel-preview {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  width: 320px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 12px 16px;
}
.sendbird-theme--light .sendbird-channel-preview {
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
}
.sendbird-theme--dark .sendbird-channel-preview {
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
}
.sendbird--mobile-mode .sendbird-channel-preview {
  width: 100%;
}
.sendbird-channel-preview:focus {
  outline: none;
}
.sendbird-channel-preview .sendbird-channel-preview__avatar {
  width: 56px;
  height: 56px;
}
.sendbird-channel-preview .sendbird-channel-preview__content {
  width: 100%;
  height: 100%;
  margin-left: 16px;
}
.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content {
  position: relative;
  max-width: calc(100% - 72px);
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper {
  display: flex;
  justify-content: space-between;
  width: 216px;
  height: 16px;
}
.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper {
  width: auto;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__broadcast-icon {
  padding-right: 4px;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__channel-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 128px;
  word-break: break-all;
  white-space: nowrap;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__total-members {
  margin-top: 2px;
  margin-left: 4px;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__header .sendbird-channel-preview__content__upper__header__frozen-icon {
  padding-left: 4px;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at {
  margin-left: 4px;
  margin-bottom: 4px;
  white-space: nowrap;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__upper .sendbird-channel-preview__content__upper__last-message-at.sendbird-message-status {
  max-width: 74px;
  justify-content: flex-end;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
  margin-top: 8px;
  width: 216px;
  height: 32px;
  display: flex;
  justify-content: space-between;
}
.sendbird--mobile-mode .sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower {
  width: 100%;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__last-message {
  overflow: hidden;
  text-overflow: ellipsis;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 12px;
}
.sendbird-channel-preview .sendbird-channel-preview__content .sendbird-channel-preview__content__lower .sendbird-channel-preview__content__lower__unread-message-count .sendbird-channel-preview__content__lower__unread-message-count__mention {
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
}
.sendbird-channel-preview .sendbird-channel-preview__action {
  position: absolute;
  top: 12px;
  right: 12px;
  display: inline-block;
}
.sendbird-channel-preview .sendbird-channel-preview__action .sendbird-iconbutton {
  display: none;
}
.sendbird-theme--light .sendbird-channel-preview:hover {
  background-color: var(--sendbird-light-background-100);
}
.sendbird-theme--dark .sendbird-channel-preview:hover {
  background-color: var(--sendbird-dark-background-500);
}
.sendbird-channel-preview:hover .sendbird-channel-preview__action .sendbird-iconbutton {
  display: inline-block;
}
.sendbird-channel-preview:hover .sendbird-channel-preview__content__upper__last-message-at {
  display: none;
}
.sendbird-channel-preview:hover .sendbird-channel-preview__content__lower__unread-message-count {
  display: none;
}

.sendbird-channel-preview--active {
  padding: 12px 16px 12px 12px;
}
.sendbird-theme--light .sendbird-channel-preview--active {
  border-left: solid 4px var(--sendbird-light-primary-300);
  border-bottom: solid 1px var(--sendbird-light-onlight-04);
  background-color: var(--sendbird-light-primary-100);
}
.sendbird-theme--dark .sendbird-channel-preview--active {
  border-left: solid 4px var(--sendbird-dark-primary-200);
  border-bottom: solid 1px var(--sendbird-dark-ondark-04);
  background-color: var(--sendbird-dark-background-700);
}
.sendbird-theme--light .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
  color: var(--sendbird-light-primary-300);
}
.sendbird-theme--dark .sendbird-channel-preview--active .sendbird-channel-preview__content__upper__header__channel-name {
  color: var(--sendbird-dark-primary-200);
}

.sendbird--mobile-mode .sendbird-channel-preview__content__lower__unread-message-count {
  position: absolute;
  right: 0;
}